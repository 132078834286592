import React, { useState, useEffect, useContext } from 'react';
import { Form, FormControl, FormGroup, FormLabel, FormSelect, Modal, Spinner } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import moment from "moment";
import { Animated } from "react-animated-css";
import { Scrollbars } from "react-custom-scrollbars-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

//API
import { AVAIABLE_OCCURENCES, TIME_SLOT } from "../../../../../gql/Query";
import { CANCELLATION_REQUEST, TIME_SLOT_AVAILABILITY } from "../../../../../gql/Mutation";
import { cartList } from './ShopService';
import useFormatServices from '../../../system/useFinalCartData';
import useCurrentCartData from '../../../system/useCurrentCartData';
import { occurrence } from '../../../system/utility';

const DateTimeModal = ({ bID, onAdd, checkAuth, closeDays, closeDate, blockedTime,
    canList, vetting, getVetDateTime, formatServices, notification, shopSlotDuration }) => {
    const { cartItems, person, currentCartItem, setCurrentCartItem, date, setDate, time, setTime} =  useContext(cartList);
    const formattedServices =  useFormatServices(cartItems, person);
    const currentCart=  useCurrentCartData(currentCartItem);
    // console.log("slot ~ shopSlotDuration:", shopSlotDuration)
    // console.log("slot ~ cartItems:", cartItems)
    const [waiting, setWaiting] = useState(false);
    // if (blockedTime) {
    //     console.log("blockedTime", blockedTime)
    // }
    // console.log(vetting)
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"))
    const [closeDateIndex, setCloseDateIndex] = useState([]);
    const [weekday, setWeekday] = useState(moment(today).format('YYYY-MM-DD'));
    const [timeshow, setTimeshow] = useState("");
    const [tempDate, setTempDate] = useState("");
    const [timeAPI, setTimeAPI] = useState([{}]);
    const [groupDates, setGroupDates] = useState([{}]);
    // console.log("slot ~ original slot from api:", timeAPI?.map((time)=>(time, moment.unix(time.s_time).utcOffset('+0000').format("h:mm a"))));    
    // console.log("slot ~ original slot from api:", timeAPI?.map((time)=>time));    
    const [block, setBlock] = useState({});

    // console.log("slot closeDays", closeDays)
    // console.log("slot closeDate", closeDate)
    // console.log("slot blockedTime", blockedTime)

    //---------------------------select time--------------------------
    const timeSlotPayload = {
        business_id: parseInt(bID),
        date: weekday,
        service_pricing_id: currentCartItem ? parseInt(currentCartItem.id) : 0
    }
    const { data, loading, refetch } = useQuery(TIME_SLOT, {
        variables: timeSlotPayload,
        fetchPolicy: "network-only"
    });
    // available occurrences query

    const {data: availableOccurrences, refetch:availableOccurrencesRefetch} = useQuery(AVAIABLE_OCCURENCES)
    //----------------checking for time slot availity------------------
    // const [timeslot_availability] = useMutation(TIME_SLOT_AVAILABILITY);

    //-----------------------CANCELLATION REQUEST---------------
    const [cancellation_request] = useMutation(CANCELLATION_REQUEST,{
        onError(err) {
        const graphQLErrors = err.graphQLErrors
        console.log("🚀 ~ onError ~ graphQLErrors:", graphQLErrors)

        if (graphQLErrors && graphQLErrors.length > 0) {
            const error = graphQLErrors[0]
            const extensions = error.extensions
            // Check if it's a validation error
            if (extensions && extensions.validation) {
            const validationErrors = extensions.validation
            // Loop through the validation errors and show each message in a toast
            Object.keys(validationErrors).forEach((key) => {
                validationErrors[key].forEach((message) => {
                toast.error(message)
                })
            })
            } else {
            // If it's a different type of error, show the general reason
            toast.error(extensions.reason || graphQLErrors[0].message)
            }
        } else {
            // Handle the case where there's no detailed GraphQL error
            toast.error('An unknown error occurred')
        }
    }
    });
    const wait = () => {
        cancellation_request({
            variables: {
                date: weekday,
                guest_id: 0,
                business_id: parseInt(bID)
            }
        }).then(({ data }) => {
            //console.log(data.cancellation_request)
            if (data?.cancellation_request?.status === 1) {
                canList();
                toast.success(data?.cancellation_request?.message);
            } else {
                toast.error(data?.cancellation_request?.message);
            }
        })
    }

    //--------------filter all the closed weekday---------------
    const isWeekday = (val) => {
        var active
        if (closeDate?.length > 0) {
            for (let cd of closeDate) {
                if (moment.unix(cd.date).format("YYYY/MM/DD") === moment(val).format("YYYY/MM/DD")) {
                    active = false;
                    break;
                } else {
                    if (closeDateIndex.length === 0) {
                        active = true;
                    } else {
                        const day = new Date(val).getDay();
                        for (let cDate of closeDateIndex) {
                            if (cDate === day) {
                                active = false;
                                break;
                            } else {
                                active = true;
                            }
                        }
                    }
                }
            }
        } else {
            if (closeDateIndex.length === 0) {
                active = true;
            } else {
                const day = new Date(val).getDay();
                for (let cDate of closeDateIndex) {
                    if (cDate === day) {
                        active = false;
                        break;
                    } else {
                        active = true;
                    }
                }
            }
        }
        return active
    };
    //------------------service date time in local storage---------------
    const setWithExpiry = (key, value) => {
        const now = new Date();
        const item = {
            value: value,
            expiry: now.getTime() + 60 * 60 * 1000,
        }
        localStorage.setItem(key, JSON.stringify(item));
    }
    const getWithExpiry = (key) => {
        const itemStr = localStorage.getItem(key)
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null
        }
        return item.value
    }

    //-----------select date---------------------
    const selectDate = (val) => {
        setDate(moment(val).format('YYYY-MM-DD'));
        setSelectedDate(moment(val).format('YYYY-MM-DD'));
        // console.log(moment(val).format('YYYY-MM-DD'), getWithExpiry("serviceDate"))
        // console.log("vetting", moment(moment(val).format('YYYY-MM-DD')).isAfter(getWithExpiry("serviceDate")))
        if (vetting) {
            setTempDate(val);
            if (moment(moment(val).format('YYYY-MM-DD')).isAfter(getWithExpiry("serviceDate"))) {
                // toast.error("You must have to select consultation call time before the time you have selected for the services!");
                setTimeAPI();
            } else {
                setWeekday(moment(val).format('YYYY-MM-DD'));
                setTimeout(() => {
                    refetch()
                }, 1000);
            }
        } else {
            setWeekday(moment(val).format('YYYY-MM-DD'));
            setWithExpiry("serviceDate", val);
            setTimeout(() => {
                refetch()
            }, 1000);
        }

        //-------------for close dates-------------------
        for (let bDate of blockedTime) {
            if (moment.unix(bDate.date).format("YYYY/MM/DD") ===
                moment(val).format("YYYY/MM/DD")) {
                setWaiting(true)
                break;
            } else {
                setWaiting(false)
            }
        }
    }

    //--------------------select time-------------------
    const changeTime = (val) => {
        if (notification) {
            let t = moment.unix(val).utcOffset('+0000').format("hh:mm a")
            setTime(t);
        } else {
            if (vetting) {
                const x = parseInt(getWithExpiry("serviceTime"));
                const y = val;
                // console.log(x, y)
                if (moment(moment(tempDate).format('YYYY-MM-DD')).isSame(getWithExpiry("serviceDate"))) {
                    if (y >= x) {
                        toast.error("You must have to select vetting call time before the time you have selected for the services!");
                    } else {
                        let t = moment.unix(val).utcOffset('+0000').format("hh:mm a");
                        setTime(t);
                        getVetDateTime(t, date);
                    }
                } else {
                    let t = moment.unix(val).utcOffset('+0000').format("hh:mm a")
                    getVetDateTime(t, date);
                }
            } else {
                setTime(moment.unix(val).utcOffset('+0000').format("hh:mm a"));
                setWithExpiry("serviceTime", val);
            }
        }
    }

    // available time slot
    // useEffect(() => {
    //     if (cartItems.length > 0) {
    //         // 1. Filter booked slots for the selected date
    //         const bookedSlots = cartItems
    //             .filter((item) => item.date === selectedDate)
    //             .map((item) => ({ s_time: moment(item.time, "h:mm a").unix(), duration: item.duration }));

    //         // 2. Calculate booked slots based on service duration and shopSlotDuration
    //         const bookedSlotsArray = bookedSlots.reduce((slots, bookedService) => {
    //             const { s_time, duration } = bookedService;

    //             // Validate service duration
    //             if (duration <= 0) {
    //                 return slots;
    //             }

    //             // Add objects for each slot needed for the booked service
    //             const slotsForService = Array.from(
    //                 { length: Math.ceil(duration / shopSlotDuration) },
    //                 (_, index) => ({ s_time: s_time + index * shopSlotDuration * 60 })
    //             );
    //             console.log("slot ~ bookedSlotsArray ~ slotsForService:", slotsForService)

    //             // Concatenate the new slots to the existing array
    //             return slots.concat(slotsForService);
    //         }, []);
    //         // console.log("slot ~ bookedSlotsArray ~ slotsForService:", bookedSlotsArray)
    //         console.log("slot ~ bookedSlotsArray ~ slotsForService:", bookedSlotsArray.map((time)=> moment.unix(time.s_time).utcOffset('+0000').format("h:mm a")))

    //         // 3. Filter available time slots
        
    //         const availableTimeSlots = timeAPI.filter((slot) => {
    //             // Check if the s_time is not present in the second array
    //             return !bookedSlotsArray.some((bookedSlot) => moment(bookedSlot.s_time).format("h:mm a") === moment(slot.s_time).format("h:mm a"));
    //         });
    //         console.log("slot ~ useEffect ~ availableTimeSlots:", availableTimeSlots);

    //         // If needed, you can extract the formatted time for logging or display purposes
    //         const formattedAvailableTimeSlots = availableTimeSlots.map((time) => moment.unix(time.s_time).utcOffset('+0000').format("h:mm a"));
    //         console.log("slot Available Time Slots:", formattedAvailableTimeSlots);




    //         // Update state with available time slots
    //         // setTimeAPI(availableTimeSlots);
    //     }
    // }, [selectedDate, cartItems, shopSlotDuration]);




    useEffect(() => {
        const weekDay = ["sunday", "monday", "tuesday", "wednesday",
            "thursday", "friday", "saturday"];
        var dayArr = []
        if (data) {
            setTimeshow(true)
            // setTimeAPI(data.business_time_slot)
             if (cartItems?.length > 0) {
                console.log('slot calculation need')
                // 1. Filter booked slots for the selected date
                const bookedSlots = cartItems
                    .filter((item) => item.date === selectedDate)
                    .map((item) => ({ s_time: moment(item.time, "hh:mm a").unix(), duration: item.duration }));

                // 2. Calculate booked slots based on service duration and shopSlotDuration
                const bookedSlotsArray = bookedSlots.reduce((slots, bookedService) => {
                    const { s_time, duration } = bookedService;

                    // Validate service duration
                    if (duration <= 0) {
                        return slots;
                    }

                    // Add objects for each slot needed for the booked service
                    const slotsForService = Array.from(
                        { length: Math.ceil(duration / shopSlotDuration) },
                        (_, index) => ({ s_time: s_time + index * shopSlotDuration * 60 })
                    );
                    console.log("slot ~ bookedSlotsArray ~ slotsForService:", slotsForService)

                    // Concatenate the new slots to the existing array
                    return slots.concat(slotsForService);
                }, []);
                // console.log("slot ~ bookedSlotsArray ~ slotsForService:", bookedSlotsArray)

                // 3. Filter available time slots
                let availableTimeSlots = data.business_time_slot?.time_slots.filter((slot) => {
                    // Check if the s_time is not present in the second array
                     const booked = bookedSlotsArray.map((bookedSlot) => moment(bookedSlot.s_time).format("hh:mm a")).includes(moment(slot.s_time).format("hh:mm a"));
                    console.log('booked', booked)
                     return booked ? null: slot;
                });
                // console.log("slot ~ useEffect ~ availableTimeSlots:", data.business_time_slot.filter((slot) => {
                // // Check if the s_time is not present in the second array
                //      const boolked = bookedSlotsArray.map((bookedSlot) => moment(bookedSlot.s_time).format("h:mm a")).includes(moment(slot.s_time).format("h:mm a"));
                //         return boolked ? null: slot;
                //     }));
                setTimeAPI(availableTimeSlots)
                // If needed, you can extract the formatted time for logging or display purposes
                if(currentCartItem?.is_group == true){
                    setGroupDates(data.business_time_slot?.date_slots?.map(itm => new Date(moment.unix(itm.s_date))))
                }
            }else{
                console.log('slot calculation not need')
                setTimeAPI(data.business_time_slot?.time_slots)
                setGroupDates(data.business_time_slot?.date_slots?.map(itm => new Date(moment.unix(itm.s_date))))
            }
        }
        if (loading) {
            setTimeshow(false)
        }
        if (closeDays) {
            closeDays.map((x) => (
                dayArr.push(weekDay.indexOf(x.weekday))
            ))
            setCloseDateIndex(dayArr);
        }
        // if (closeDate) {
        //     console.log("closeDays", closeDate)
        //     setBlock(closeDate.map(itm => new Date(moment.unix(itm.date))))
        //     // blockedTime.map(x => console.log(new Date(moment.unix(x.date))))
        //     //console.log("blockedTime", new Date(itm?.date))
        // }
        if (blockedTime) {
            setBlock(blockedTime.map(itm => new Date(moment.unix(itm.date))))
            // blockedTime.map(x => console.log(new Date(moment.unix(x.date))))
            // console.log("blockedTime", new Date(itm?.date))
        }
    }, [data, loading, closeDays, closeDate, blockedTime,selectedDate, cartItems, shopSlotDuration])
    // avaible occurrence
    useEffect(() => {
        if (currentCartItem?.is_group === true && time ) {
            // Refetch the query when the date or time changes
            availableOccurrencesRefetch({
                business_id: parseInt(bID),
                date: selectedDate,
                service_id: currentCartItem.service_id,
                time: time,
            })
        }
        if(availableOccurrences){
            const {available_occurrences} = availableOccurrences.group_service_available_occurrences
             setCurrentCartItem({ 
                ...currentCartItem, 
                available_occur: available_occurrences,
                occurrences: available_occurrences >= 1 ? 1 : 0
            })
        }
    }, [currentCartItem?.is_group, selectedDate, time, availableOccurrences]);

    return (
        <>
            {waiting ?
                <>
                    {!vetting &&
                        <Modal.Header closeButton>
                            <Modal.Title>Wait in the cancellation list</Modal.Title>
                        </Modal.Header>
                    }
                    <Modal.Body>
                        <div className="text-center">
                            <h2> All the schedules are occupied! </h2>
                            <p>
                                If any schedule gets free, do you want us to send email notification to you?
                            </p>
                            <div className="d-flex justify-content-center venuItem">
                                <button className='btn venuBtn me-2' onClick={() => { wait() }}>Yes</button>
                                <button className='btn venuBtn ms-2' onClick={() => { setWaiting(false) }}>No</button>
                            </div>
                        </div>
                    </Modal.Body>
                </>
                : <>
                    {!vetting &&
                        <Modal.Header closeButton>
                            <Modal.Title>Select date and time</Modal.Title>
                        </Modal.Header>
                    }
                    <Modal.Body>
                        <div className='d-flex justify-content-center custom-datepicker mx-auto'>
                            { currentCartItem?.is_group === true ? 
                                <DatePicker
                                    inline
                                    dateFormat="yyyy/MM/dd"
                                    selected={today}
                                    onChange={selectDate}
                                    minDate={today}
                                    includeDates={groupDates}
                                    highlightDates={block}
                                />: 
                                <DatePicker
                                    inline
                                    dateFormat="yyyy/MM/dd"
                                    selected={today}
                                    onChange={selectDate}
                                    minDate={today}
                                    filterDate={isWeekday}
                                    highlightDates={block}
                                />
                            }
                            <div>
                                <div className='text-center font-chzday chuzy-time react-datepicker__header'>Time</div>
                                <div className='available-time'>
                                    <Scrollbars>
                                        {loading &&
                                            <div className='d-flex justify-content-center'>
                                                <Spinner animation="border" />
                                            </div>
                                        }
                                        {timeshow && timeAPI?.length > 0 &&
                                            <Animated animationIn="fadeInDown" animationOut="fadeInDown" isVisible={true} animationInDelay={300}>
                                                {timeAPI.map((x) => (
                                                    <div key={x.s_time} className="d-flex align-items-center">
                                                        <input className='form-check-input' type="radio" id={x.s_time} name="time" onChange={() => { changeTime(x.s_time) }} />
                                                        <label htmlFor={x.s_time}>{moment.unix(x.s_time).utcOffset('+0000').format("hh:mm a")}</label>
                                                    </div>
                                                ))}
                                            </Animated>
                                        }
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                        <div className='repeat_group mt-4 mx-auto'>
                            {
                                currentCartItem?.group_type === 'repeated' && !vetting ?
                                <FormGroup>
                                    <FormLabel>Frequency</FormLabel>
                                    <FormSelect className='mb-3'
                                        defaultValue={currentCartItem?.frequency}
                                        onChange={() => console.log('frequency')}
                                    >
                                        <option>{currentCartItem?.frequency}</option>
                                    </FormSelect>
                                    <FormLabel>Occurrence</FormLabel>
                                    <FormSelect
                                        defaultValue={currentCartItem?.occurrences}
                                        onChange={(e) => {
                                        setCurrentCartItem({ ...currentCartItem, occurrences: +e.target.value })
                                        }}
                                    >
                                        <option selected value={currentCartItem?.occurrences >= 1 ? '1' : '0'}>{currentCartItem?.occurrences >= 1 ? '1' : 'select occurrences'}</option>
                                        {occurrence &&
                                            occurrence
                                                .filter((item) => item.value <= (currentCartItem?.available_occur))
                                                .map((occurrenceItem) => (
                                                    <option
                                                        key={occurrenceItem.id}
                                                        value={occurrenceItem.value}
                                                    >
                                                        {occurrenceItem.value}
                                                    </option>
                                                ))}
                                    </FormSelect>
                                </FormGroup> : null
                            }
                        </div>
                    </Modal.Body>
                    {vetting && notification &&
                        <div className="select-time-btn-wrap venuItem pb-2">
                            <button className="btn primaryBtn"
                                onClick={() => { checkAuth(time, date) }}
                                disabled={(date && time !== "") ? false : true}>
                                Confirm
                            </button>
                        </div>
                    }
                    {!vetting &&
                        <div className="select-time-btn-wrap venuItem pb-2">
                            <button className="btn primaryBtn"
                                onClick={()=>{
                                    // checkAvailability();
                                    onAdd(currentCartItem)
                                }}
                                disabled={(currentCartItem.group_type === 'repeated' && (currentCartItem.available_occur == 0 || currentCartItem.available_occur == "" || currentCartItem.occurrences == 0)) || (!date || !time)}>
                                Next <i className="arrow fa fa-angle-right" />
                            </button>
                        </div>
                    }
                </>}
        </>
    )
}

export default DateTimeModal
