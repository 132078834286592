export const occurrence = [
//   {id: 1, value: '1', status: false},
  {id: 2, value: '2', status: true},
  {id: 3, value: '3', status: true},
  {id: 4, value: '4', status: true},
  {id: 5, value: '5', status: true},
  {id: 6, value: '6', status: true},
  {id: 7, value: '7', status: true},
  {id: 8, value: '8', status: true},
  {id: 9, value: '9', status: true},
  {id: 10, value: '10', status: true},
  {id: 11, value: '11', status: true},
  {id: 12, value: '12', status: true},
  {id: 13, value: '13', status: true},
  {id: 14, value: '14', status: true},
];

export const totalPayable=(serviceAmount, upfront)=>{
  return (serviceAmount * upfront / 100).toFixed(2)
}
export const findMinDateTime = (appointments) => {
    if (appointments.length === 0) return null;
    
    const convertTo24Hour = (time12h) => {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'pm') {
            hours = parseInt(hours, 10) + 12;
        }
        return String(hours).padStart(2, '0') + ':' + minutes.padStart(2, '0');
    };

    let minDateTime = null;

    for (let i = 0; i < appointments.length; i++) {
        if (isNaN(new Date(appointments[i].date))) {
            continue;
        }
        if (appointments[i].__typename === 'ServicePricing') {
            continue; // Skip ServicePricing appointments
        }
        const appointmentDateTime = new Date(
            parseInt(appointments[i].date.split('-')[2]), // Year
            parseInt(appointments[i].date.split('-')[1]) - 1, // Month (subtract 1 because months are zero-indexed)
            parseInt(appointments[i].date.split('-')[0]), // Day
            convertTo24Hour(appointments[i].time).split(':')[0], // Hours
            convertTo24Hour(appointments[i].time).split(':')[1] // Minutes
        );
        if (isNaN(appointmentDateTime.getTime())) {
            continue;
        }
        if (minDateTime === null || appointmentDateTime < minDateTime) {
            minDateTime = appointmentDateTime;
        }
    }
    return minDateTime;
};


export const formatTimeFromHoursMinutes = (hours, minutes) => {
    const formattedHours = hours > 12 
        ? (hours - 12).toString().padStart(2, '0') 
        : hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    return `${formattedHours}:${formattedMinutes} ${period}`;
};

export const imageBaseURL = 
  window.location.host.includes('localhost:3000') ||
  window.location.host.includes('testguest.chuzeday.com') ? 
  'https://testguest.chuzeday.com' : 'https://chuzeday.com'